


























import {
	Component,
	Vue
} from "vue-property-decorator";
import {
	apiSystemCacheClear
} from "@/api/setting/system_maintain/system_maintain"
import LsDialog from '@/components/ls-dialog.vue'
import {
	RequestPaging
} from "@/utils/util"
@Component({
	components: {
		LsDialog
	},
})
export default class SystemCache extends Vue {
	/** S Data **/
	lists = [{
		content: '系统缓存',
		description: '系统运行过程中产生的各类缓存数据'
	}]
	// pager: RequestPaging = new RequestPaging()
	/** E Data **/

	/** S Methods **/
	onSystemCacheClear() {
		apiSystemCacheClear()
		.then(res => {
			console.log(res)
		})
		.catch(res => {
			console.log(res)
		})
	}
	/** E Methods **/

	/** S Life Cycle **/
	created() {}
	/** E Life Cycle **/
}
